import { Pipe, PipeTransform } from '@angular/core';
import { LocalstorageService } from '../services/localstorage.service';

@Pipe({
  name: 'hasrole'
})
export class HasrolePipe implements PipeTransform {

  constructor(
    private localStorageService: LocalstorageService
  ) {}

  transform(role: String): boolean {
    return this.localStorageService.hasRole(role);
  }

}
