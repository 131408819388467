import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { API_ENDPOINTS } from '../api-endpoints';

@Injectable({
  providedIn: 'root'
})
export class PermissionService {

  private apiEndpoint = API_ENDPOINTS;

  constructor(
    private http: HttpClient
  ) { }

  getLoggedInUserPermissions(data): Observable<any> {
    return this.http.post<any>(this.apiEndpoint.permission, data);
  }

  getPermissionsForRole(forRole = null): Observable<any> {
    let params = new HttpParams();
    if(forRole) {
      params = params.append('for', `${forRole}`);
    }

    return this.http.get<any>(this.apiEndpoint.admin.permissions_list, {params});
  }
}
