import { Injectable } from '@angular/core';
import { Globals } from 'src/app/globals';
import { environment } from '../../../environments/environment';
import { USER_PROFILE } from './user-profile';

declare const PUBNUB: any;

@Injectable({
  providedIn: 'root'
})
export class PubnubService {

  public pubnub: any;
  userProfile = USER_PROFILE;

  constructor(
    public globals: Globals
  ) { }

  init(): any {
    this.pubnub = PUBNUB.init({
      publish_key: environment.pubnub.publishKey,
      subscribe_key: environment.pubnub.subscribeKey,
      uuid: this.userProfile.user_id
    });
    return this.pubnub;
  }

  changeState(channel, data): void {
    const state = {
      uuid: this.userProfile.user_id,
      full_name: this.userProfile.full_name,
      timestamp: Math.floor(Date.now() / 1000)
    };
    Object.assign(state, data);
    this.pubnub.state({
      channel,
      uuid: this.userProfile.user_id,
      state
    });
  }

  getPresenceHistory(c): void {
    this.pubnub.here_now({
      channel: c,
      callback: (h) => {
          h.uuids.forEach(element => {   
          this.pubnub.state({
            channel: c,
            uuid: element,
            callback: (m) => 
            {   
              if (m?.status === "occupied") {    
                  this.globals.userPresenceNew.set(Number(m.patientReviewed), m.reviewer);  
                  this.globals.patientListUnderReviewNumber++;             
              }
              else if (m?.status === "vacant") {
                this.globals.userPresenceNew.delete(Number(m.patientReviewed));
              }            
            }
          });
        });
      }
    });
  }
}
