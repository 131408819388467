import { SideNavInterface } from '../../interfaces/side-nav.type';
export const ROUTES: SideNavInterface[] = [
    // {
    //     path: 'home',
    //     title: 'Dashboard',
    //     iconType: 'nzIcon',
    //     iconTheme: 'outline',
    //     icon: 'dashboard',
    //     submenu: []
    // },
    {
        path: 'company',
        title: 'Organizational Units',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'cluster',
        submenu: []
    },
    {
        path: 'home-health',
        title: 'Home Health Care',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'shop',
        submenu: []
    },
    {
        path: 'user-access-roles',
        title: 'User Access Controls',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'control',
        submenu: []
    }
    // {
    //     path: 'admins',
    //     title: 'Admin Manager',
    //     iconType: 'nzIcon',
    //     iconTheme: 'outline',
    //     icon: 'user',
    //     submenu: []
    // },
    // {
    //     path: 'careteams',
    //     title: 'Careteam Manager',
    //     iconType: 'nzIcon',
    //     iconTheme: 'outline',
    //     icon: 'user',
    //     submenu: []
    // },
    // {
    //     path: 'providers',
    //     title: 'Provider Manager',
    //     iconType: 'nzIcon',
    //     iconTheme: 'outline',
    //     icon: 'user',
    //     submenu: []
    // },
    // {
    //     path: '',
    //     title: 'Reporting',
    //     iconType: 'nzIcon',
    //     iconTheme: 'outline',
    //     icon: 'appstore',
    //     submenu: [
    //         {
    //             path: 'reports/admissions',
    //             title: 'Admissions Report',
    //             iconType: 'nzIcon',
    //             iconTheme: 'outline',
    //             icon: '',
    //             submenu: []
    //         },
    //         {
    //             path: '',
    //             title: 'Billing Report',
    //             iconType: 'nzIcon',
    //             iconTheme: 'outline',
    //             icon: '',
    //             submenu: [
    //                 {
    //                     path: 'reports/ccm-billing',
    //                     title: 'CCM',
    //                     iconType: 'nzIcon',
    //                     iconTheme: 'outline',
    //                     icon: '',
    //                     submenu: []
    //                 },
    //                 {
    //                     path: 'reports/billing',
    //                     title: 'RPM',
    //                     iconType: 'nzIcon',
    //                     iconTheme: 'outline',
    //                     icon: '',
    //                     submenu: []
    //                 },
    //             ]
    //         },
    //         {
    //             path: 'reports/outcomes-v2',
    //             title: 'Blood Pressure Population Report',
    //             iconType: 'nzIcon',
    //             iconTheme: 'outline',
    //             icon: '',
    //             submenu: []
    //         },
    //         {
    //             path: '',
    //             title: 'Care Management',
    //             iconType: 'nzIcon',
    //             iconTheme: 'outline',
    //             icon: '',
    //             submenu: [
    //                 {
    //                     path: '',
    //                     title: 'RPM',
    //                     iconType: 'nzIcon',
    //                     iconTheme: 'outline',
    //                     icon: '',
    //                     submenu: [
    //                         {
    //                             path: 'reports/readings-compliance',
    //                             title: 'Readings',
    //                             iconType: 'nzIcon',
    //                             iconTheme: 'outline',
    //                             icon: '',
    //                             submenu: []
    //                         }
    //                     ]
    //                 }
    //             ]
    //         },
    //         {
    //             path: 'reports/non-adherence',
    //             title: 'Non-Adherence Report',
    //             iconType: 'nzIcon',
    //             iconTheme: 'outline',
    //             icon: '',
    //             submenu: []
    //         },
    //         {
    //             path: 'reports/outcomes',
    //             title: 'Outcome Report',
    //             iconType: 'nzIcon',
    //             iconTheme: 'outline',
    //             icon: '',
    //             submenu: []
    //         },
    //         {
    //             path: 'reports/performance-score-card',
    //             title: 'Performance Score Card',
    //             iconType: 'nzIcon',
    //             iconTheme: 'outline',
    //             icon: '',
    //             submenu: []
    //         },
    //         {
    //             path: 'reports/progress-card',
    //             title: 'Provider Score Card',
    //             iconType: 'nzIcon',
    //             iconTheme: 'outline',
    //             icon: '',
    //             submenu: []
    //         }
    //     ]
    // }
];
