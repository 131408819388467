import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from '../../services/auth.service';
import { LocalstorageService } from '../../services/localstorage.service';
import { ThemeConstantService } from '../../services/theme-constant.service';
import { USER_PROFILE } from '../../services/user-profile';
import { Globals } from 'src/app/globals';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html'
})

export class HeaderComponent implements OnInit{

    APP_ENDPOINTS = environment.appEndpoints;

    userProfile = USER_PROFILE;
    searchVisible = false;
    quickViewVisible = false;
    isFolded: boolean;
    isExpand: boolean;
    loggingOut = false;
    // userProfile: any;

    careplanDay = 1;
    careplanDayUpdated = false;

    notificationList = [
        {
            title: 'You received a new message',
            time: '8 min',
            icon: 'mail',
            color: 'ant-avatar-' + 'blue'
        },
        {
            title: 'New user registered',
            time: '7 hours',
            icon: 'user-add',
            color: 'ant-avatar-' + 'cyan'
        },
        {
            title: 'System Alert',
            time: '8 hours',
            icon: 'warning',
            color: 'ant-avatar-' + 'red'
        },
        {
            title: 'You have a new update',
            time: '2 days',
            icon: 'sync',
            color: 'ant-avatar-' + 'gold'
        }
    ];

    assets = {
        logo: 'assets/images/logo/logo.png',
        logo_fold: 'assets/images/logo/logo-fold.png'
      };

    constructor(
        private themeService: ThemeConstantService,
        private authService: AuthService,
        private localStorageService: LocalstorageService,
        private router: Router,
        public globals: Globals
        ) {}

    ngOnInit(): void {
        this.themeService.isMenuFoldedChanges.subscribe(isFolded => this.isFolded = isFolded);
        this.themeService.isExpandChanges.subscribe(isExpand => this.isExpand = isExpand);
    }

    toggleFold(): void {
        this.isFolded = !this.isFolded;
        this.themeService.toggleFold(this.isFolded);
    }

    toggleExpand(): void {
        this.isFolded = false;
        this.isExpand = !this.isExpand;
        this.themeService.toggleExpand(this.isExpand);
        this.themeService.toggleFold(this.isFolded);
    }

    searchToggle(): void {
        this.searchVisible = !this.searchVisible;
    }

    quickViewToggle(): void {
        this.quickViewVisible = !this.quickViewVisible;
    }

    logout(): void {
        // const loginUrl = this.userProfile.is_ochs ? '/ochs' : '/authentication/login';
        const loginUrl = '/authentication/login';
        this.loggingOut = true;
        this.authService.logout().subscribe(
            (data) => {
                this.localStorageService.unsetUserProfile();
                localStorage.setItem('isLoggedIn', 'false');
                this.router.navigate([loginUrl]);
            },
            (error) => {
                console.log('error');
                this.router.navigate([loginUrl]);
            }
        );
    }

    setActiveBlock(val): void {
        this.globals.activeBlock = val;
        this.globals.setActiveBlockObserver(val);
        this.router.navigate(['/home']);
    }

    goToPatient(id): void{
        // console.log(id);
        // this.globals.lastUrl = '/newMessage';
        this.router.navigate(['patients', id]);
    }
}
