import { Routes } from '@angular/router';

export const CommonLayout_ROUTES: Routes = [
    {
        path: '',
        loadChildren: () => import('../../admin/admin.module').then(m => m.AdminModule),
    },
    {
        path: 'dashboard',
        loadChildren: () => import('../../dashboard/dashboard.module').then(m => m.DashboardModule),
    }
];