import { Component } from '@angular/core';
import { ROUTES } from './side-nav-routes.config';
import { ThemeConstantService } from '../../services/theme-constant.service';
import { USER_PROFILE } from '../../services/user-profile';
import { PermissionService } from '../../services/permission.service';
import { Globals } from 'src/app/globals';

@Component({
    selector: 'app-sidenav',
    templateUrl: './side-nav.component.html'
})

export class SideNavComponent{

    public menuItems: any[]
    isFolded : boolean;
    isSideNavDark : boolean;
    isExpand : boolean;
    userProfile = USER_PROFILE;

    constructor( 
        private themeService: ThemeConstantService,
        private permission: PermissionService,
        public globals: Globals) {}

    ngOnInit(): void {
        this.menuItems = ROUTES.filter(menuItem => menuItem);
        // this.getPermissions();               
        this.themeService.isMenuFoldedChanges.subscribe(isFolded => this.isFolded = isFolded);
        this.themeService.isExpandChanges.subscribe(isExpand => this.isExpand = isExpand);
        this.themeService.isSideNavDarkChanges.subscribe(isDark => this.isSideNavDark = isDark);
    }

    closeMobileMenu(): void {
        if (window.innerWidth < 992) {
            this.isFolded = false;
            this.isExpand = !this.isExpand;
            this.themeService.toggleExpand(this.isExpand);
            this.themeService.toggleFold(this.isFolded);
        }
    }

    getPermissions(): void {
        // if (this.userProfile.role == 'super_super_admin') {
            this.menuItems.push({
                path: 'user-access-roles',
                title: 'User Access Controls',
                iconType: 'nzIcon',
                iconTheme: 'outline',
                icon: 'control',
                submenu: []
            });
        // }
        // this.permission.getLoggedInUserPermissions({}).subscribe({
        //   next: (data) => {
        //     if (data.data?.includes('device_readings'))
        //     { 
        //         let extraMenu = {
        //             path: 'device-list',
        //             title: 'Devices',
        //             iconType: 'nzIcon',
        //             iconTheme: 'outline',
        //             icon: 'tablet',
        //             submenu: []
        //         };      
        //         this.menuItems.push(extraMenu);
        //     }
        //     if (data.data?.includes('device_tracking'))
        //     { 
        //         let extraMenu = {
        //             path: 'device-shipping',
        //             title: '',
        //             iconType: 'fontawesome',
        //             iconTheme: 'fas',
        //             icon: 'fa-shipping-fast',
        //             submenu: []
        //         };      
        //         this.menuItems.push(extraMenu);
        //     }     
        //     if (data.data?.includes('bulk_sms'))
        //     { 
        //         this.globals.bulkMessagePermission = true;
        //     }        
        //   },
        //   error: (error) => {
        //     console.log(error);
        //   }
        // });
      }
}
